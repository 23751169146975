$maxWidth: rem-calc(1200);

$colors: (
	"green": #3E6A6D,
	"lightGrey": #F0EBE4,
	'warmGrey': #C8B078,
	'lightGreen': #9EBD34,
	'cyan': #0085C4,
	'pink': #D53868
);


.rainbowGradient {
background: #e52321;
background: -moz-linear-gradient(45deg, #e52321 0%, #a25299 18%, #2352a0 34%, #66bfdd 52%, #64b54f 70%, #e5e000 85%, #e52321 100%);
background: -webkit-linear-gradient(45deg, #e52321 0%,#a25299 18%,#2352a0 34%,#66bfdd 52%,#64b54f 70%,#e5e000 85%,#e52321 100%);
background: linear-gradient(45deg, #e52321 0%,#a25299 18%,#2352a0 34%,#66bfdd 52%,#64b54f 70%,#e5e000 85%,#e52321 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e52321', endColorstr='#e52321',GradientType=1 );
}


$bodyFontColor: #4d4d4d;

//$baseFontSize: 100%;
$baseFontSize: 16px;
$baseFontFamily: 'Open Sans', sans-serif;

$light: 300;
$bold: 700;

$globalRadius: rem-calc(10);
