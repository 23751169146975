@import "reset";
@import "unit";
@import "breakpoints";
@import "settings";
@import "grid";


.showForSr {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

.onlyMobyle {
	@include breakpoint(medium) {
	position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
	}
}

.noMobile {
	@include breakpoint(small down){
		position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
	}
}



body {
	font-family: $baseFontFamily;
	font-size: $baseFontSize;
	font-weight: $light;
	color: $bodyFontColor;
	line-height: 1.2rem;
}


h1 {
	font-size:3rem;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: -0.1rem;
	text-align: center;
	margin-bottom: 1.5rem;
	width: 100%;
	line-height: 3.1rem;
}
  

h2 {
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 1.5rem;
	font-size: 2rem;
	width: 100%;
	line-height: 2rem;
	font-weight: $light;
}


p {
	font-weight: 400;
}
 

strong {
	font-weight: $bold;
}

header {
	padding-top: rem-calc(120);
	width: 100%;
	nav {
		width: 100%;
		height: rem-calc(120);
		position: fixed;
		top:0;
		left: 0;
		background: white;
		
		#logo {
			margin: 0 auto;
			display: block;
			height: rem-calc(110);
			margin-top:rem-calc(5);
		}
		
	}
	
	h1, p {
		color:white;
	}
	
	@extend .rainbowGradient;
	
}

.parallax {
	background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	height: 600px;
}


.fwImg {
	display: block;
	width: 100%;
}

footer {
	background-color: #d0d0d0;
	p {
		text-align: center;
		width: 100%;
	}
	
	a {
		font-weight: $bold;
		color:black;
		text-decoration: none;
	}
	
}