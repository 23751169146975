/**
 * Converts one or more pixel values into matching rem values.
 */
$remBase: 16px;

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -zf-bp-to-em($value) {
    // Pixel and unitless values are converted to rems
    @if unit($value) == 'px' or unitless($value) {
        $value: rem-calc($value, $base: 16px);
    }

    // Then the value is converted to ems
    @return strip-unit($value) * 1em;
}


@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base: $rem-base)  {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
    @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
    @return $value;
}

@function rem-calc($values, $base: $remBase) {
    $max: length($values);
    @if $max == 1 { @return convert-to-rem(nth($values, 1), $base); }
    $remValues: ();
    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base));
    }
    @return $remValues;
}