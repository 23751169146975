.flexCont {
    max-width: $maxWidth;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    width: 80%;
    padding: 50px 0;
	
	@include breakpoint(medium){ 
		padding: 100px 0;
	}
	
	@include breakpoint(large){ 
		padding: 200px 0;
	}
	
	img {
		width: 100%;
		height: auto;
                align-self: center;
	}
    
    .col_1 {
        width: 100%;
    }

    .col_2 {
        width: 50%;
    }

    .col_3 {
        width: 33.33333%;
    }

    .col_4 {
        width: 25%;
    }

    .col_5 {
        width: 20%
    }

    .col_6 {
        width: 16.6666%
    }
	
	[class*='col_']{
		position: relative;
	}
	
	&.pad {
		
		@include breakpoint(small){
			[class*='col_']{
				padding: 0 rem-calc(10px);
			}
		}
		
	}
	
}

.flexCont.fw {
    max-width: 100%;
    width: 100%;
    padding: 0;
}


@media(max-width:640px){
	.flexCont {
		.col_1, .col_2, .col_3 {
			width: 100%;
		}
		
		.col_4, .col_5, .col_6{
			width: 50%;
		}	
	}
}

